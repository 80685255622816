// image.jsx
import React from "react";

export const Image = ({ title, largeImage, smallImage, isCategory }) => {
  return (
    <div className={`image-container ${isCategory ? "category" : ""}`}>
      <div className="image-wrapper">
        <a
          href={largeImage}
          title={title}
          data-lightbox-gallery="gallery1"
          onClick={(e) => !isCategory && e.preventDefault()}
        >
          <div className="hover-overlay">
            <div className="hover-content">
              <h3>{title}</h3>
              {/* {!isCategory && <span className="view-icon">+</span>} */}
            </div>
          </div>
          <img
            src={smallImage}
            className="gallery-image"
            alt={title}
            loading="lazy"
          />
        </a>
      </div>
    </div>
  );
};