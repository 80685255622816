import React, { useState } from "react";
import { Image } from "./image";

export const Gallery = (props) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
            Explore our work by category. Click any category to see its full
            collection.
          </p>
        </div>

        {!selectedCategory ? (
          // Category Grid
          <div className="categories-grid">
            {props.data?.map((category, i) => (
              <div
                key={`category-${i}`}
                className="category-item"
                onClick={() => setSelectedCategory(category)}
              >
                <Image
                  title={category.name}
                  smallImage={category.coverImage}
                  isCategory={true}
                />
                <h3 className="category-title">{category.name}</h3>
              </div>
            ))}
          </div>
        ) : (
          // Category Images
          <div className="category-images">
            <button
              className="back-button"
              onClick={() => setSelectedCategory(null)}
            >
              &larr; Back to Categories
            </button>
            <h3 className="selected-category-title">{selectedCategory.name}</h3>
            <div className="images-grid">
              {selectedCategory.images.map((image, i) => (
                <Image
                  key={`image-${i}`}
                  title={image.title}
                  smallImage={image.src}
                  largeImage={image.src}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};